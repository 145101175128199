:root {
    --y-header: 0;

    --padding-xxxxxxxl: #{(140px / 16px) * 1rem};
    --padding-xxxxxxl: #{(104px / 16px) * 1rem};
    --padding-xxxxxl: #{(88px / 16px) * 1rem};
    --padding-xxxxl: #{(72px / 16px) * 1rem};
    --padding-xxxl: #{(64px / 16px) * 1rem};
    --padding-xxl: #{(56px / 16px) * 1rem};
    --padding-xl: #{(48px / 16px) * 1rem};
    --padding-l: #{(40px / 16px) * 1rem};
    --padding-m: #{(32px / 16px) * 1rem};
    --padding-s: #{(24px / 16px) * 1rem};
    --padding-xs: #{(16px / 16px) * 1rem};
    --padding-xxs: #{(8px / 16px) * 1rem};
    --padding-xxxs: #{(4px / 16px) * 1rem};

    --header-height: #{(120px / 16px) * 1rem};
    --toggle-width: #{(48px / 16px) * 1rem};

    --max-width-box: #{(785px / 16px) * 1rem};

    --gap: #{(8px / 16px) * 1rem};
}

@media (max-width: $smartphone) {
    :root {
        --header-height: #{(88px / 16px) * 1rem};
    }
}

@mixin grid-container() {
    --min-padding: #{space(4)};
    --padding-grid-h: max(var(--min-padding), calc(var(--min-padding) + var(--size-offset)));

    @media (max-width: $smartphone) {
        --padding-grid-h: var(--padding-s);
    }

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding-left: var(--padding-grid-h);
    padding-right: var(--padding-grid-h);
}

@function securePadding($padding) {
    @return max($padding, calc(#{$padding} + var(--size-offset)));
}

@function securePaddingBig($padding) {
    @return max($padding, calc(#{$padding} + var(--size-offset) + var(--size-offset-big)));
}