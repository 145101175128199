.__touch {
    .block-team {}

    .block-team__slider {
        > .holder {
            -webkit-overflow-scrolling: touch;
            overflow-y: hidden;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .block-team__slider__item {
            scroll-snap-align: center;
        }
    }
}