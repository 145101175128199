#Report {
    --font-size-h1: min(76px, var(--font-size-xxxxxxxlarge));
    --font-size-ok: var(--font-size-xxxlarge);
    --padding-v: #{space(5)};
    --padding-h: #{space(5)};
    --padding-bottom: #{space(6)};
    --padding-top: calc(var(--header-height) + #{space(12)});

    @media (max-width: $smartphone) {
        --font-size-h1: var(--font-size-xlarge);
        --font-size-ok: var(--font-size-small);
        --padding-bottom: #{space(3)};
        --padding-top: calc(var(--header-height) + #{space(8)});
        --padding-v: #{space(3)};
        --padding-h: #{space(3)};
    }
}

#Report {
    background-color: var(--black);
    color: var(--white);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;

    @include z-index($z-index-windows);
    @include font-sans();

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    & > .content {
        height: 100%;
        left: 0;
        padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
        position: absolute;
        top: 0;
        width: 100%;

        @include isTouch() {
            overflow-y: scroll;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;
        }

        @include z-index(1);
    }

    .headline {
        @include font-sans();
        font-size: var(--font-size-h1);
    }

    .content__wrapper {
        position: relative;
    }

    .line-parent {
        overflow: hidden;
    }

    .ko {
        @include z-index($z-index-3D);

        background-color: var(--black);
        color: var(--fluor);
        font-size: var(--font-size-ok);
        height: 110%;
        margin-top: #{space(-2)};
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        visibility: hidden;
        width: 100%;
    }

    form {
        --color-success: var(--white);

        margin-top: #{space(6)};

        .form__footer {
            justify-content: flex-start;
        }

        .--checkbox {
            margin-top: #{space(8)};
        }

        fieldset {
            &.--focus,
            &.--success,
            &.--error {
                label {
                    color: var(--white);
                }
            }

            input {
                color: var(--white);
            }
        }
    }

    .close {
        @include z-index($z-index-wrap);
    
        --rot: 0deg;
        --fill: var(--white);
        --ease: var(--ease-in-out-quad);
    
        position: absolute;
        right: #{space(3)};
        top: #{space(3)};
        transform: rotate(var(--rot));
        transform-origin: center center;
        transition: transform 0.4s var(--ease);
    
        path {
            fill: var(--fill);
        }

        @include isCursor() {
            &:hover {
                --rot: 135deg;
            }
        }
    }

    @media (min-width: $smartphone) {
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        .content {
            grid-column: 4 / 12;
        }

        form {
            margin-top: #{space(8)};

            .form__wrapper {
                display: flex;
                flex-wrap: wrap;
                gap: #{space(5)};

                fieldset {
                    min-width: calc(50% - #{space(2.5)});
                }

                .--checkbox {
                    margin-top: #{space(9)};
                }
            }
        }

        .close {
            right: #{space(4)};
            top: #{space(4)};
        }  
    }
}
