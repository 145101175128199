.block-report {
    --padding-v: #{space(8)};
    --padding-h: #{space(5)};
    --font-size: var(--font-size-large);

    @media (max-width: $smartphone) {
        --padding-v: #{space(8)};
        --padding-h: #{space(3)};
        --font-size: var(--font-size-base);
    }
}

.block-report {
    @include font-sans();
    padding: var(--padding-v) var(--padding-h);

    button {
        @include basic-a();
        font-size: var(--font-size);
        text-decoration: underline;
    }

    @media (min-width: $smartphone) {
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        > * {
            grid-column: 5 / 13;
        }
    }
}
