.billboard-project {
    --font-size-h1: var(--font-size-xxxxxxxlarge);
    --font-size-big: var(--font-size-xxxxlarge);
    --font-size: var(--font-size-base);
    --highlight-color: var(--fluor);

    --max-width: #{(990px / 16px) * 1rem};
    --padding-top: calc(var(--header-height) + #{space(12)});
    --padding-bottom: 0;
    --padding-h: #{securePadding(space(4))};
    --margin-bottom: #{space(21)};

    @media (max-width: $smartphone) {
        --font-size-h1: var(--font-size-xlarge);
        --font-size-big: var(--font-size-base);
        --font-size: var(--font-size-xsmall);
        --padding-top: calc(var(--header-height) + #{space(8)});
        --padding-bottom: #{space(4)};
        --padding-h: #{space(3)};
        --margin-bottom: #{space(9)};
    }

    .hero + & {
        --padding-top: var(--header-height);
    }
}

.billboard-project {
    font-size: var(--font-size);
    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
    margin-bottom: var(--margin-bottom);

    .line-parent {
        overflow: hidden;
    }

    .headline {
        h1 {
            @include font-sans(1.3);
            font-size: var(--font-size-h1);
            max-width: var(--max-width-box);
            margin: 0;
        }

        margin: 0 0 #{space(2)};
    }

    .subtitle {
        max-width: var(--max-width-box);
        @include font-sans();
        color: var(--gray-900);
        font-size: var(--font-size-big);
        margin: 0 0 #{space(4)};
    }

    .text {
        @include font-sans();
        font-size: var(--font-size);
        margin: 0;
        max-width: var(--max-width);

        p:not(:last-child) {
            margin-bottom: #{space(2.5)};
        }
    }

    .categories {
        @include font-sans();
        font-size: var(--font-size);
        margin: 0;
    }

    @media (max-width: $smartphone) {
        .categories {
            margin: 0 0 #{space(6)};
            
            li {
                margin: 0 0 #{space(.5)};
            }
        }
    }

    @media (min-width: $smartphone) {
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        > * {
            grid-column: 5 / 12;
        }

        &__wrapper {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-column: 1 / 13;
        }

        .subtitle {
            margin: 0 0 #{space(5.25)};
        }

        .categories {
            grid-column: 1 / 5;

            li {
                margin: 0 0 #{space(.75)};
            }
        }

        .text {
            grid-column: 5 / 12;

            p {
                margin-bottom: #{space(3.5)};
            }
        }
    }
}
