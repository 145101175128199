#awwwards {
    position: fixed;
    z-index: 999;
    transform: translateY(-50%); 
    top: 50%;
    right: 0;

    svg {
        width: 53px;
        height: auto;
    }

    a {
        display: block;
    }
}

@media (max-width: $smartphone) {
    #awwwards {
        transform: none; 
        top: auto;
        bottom: var(--padding-xs);

        svg {
            width: 32px;
            height: auto;
        }
    }
}