.link-chevron {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    span:last-child {
        height: rem(19);
        margin-left: #{space(2)};
        overflow: hidden;
        position: relative;
        width: rem(12);

        svg {
            height: 100%;
            transition: transform 0.2s ease-out;
            width: 100%;
        }

        svg:first-child {
            position: absolute;
            top: 0;
            left: -150%;
        }
    }

    @include isCursor() {
        &:hover span svg {
            transform: translate3d(150%, 0, 0);
        }
    }
}
