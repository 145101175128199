.work {
    --font-size-h2: var(--font-size-xlarge);
    --font-size: var(--font-size-base);
    --font-size-categ: var(--font-size-xxsmall);
    --max-width: #{(600 / 16) * 1rem};

    @media (max-width: $smartphone) {
        --font-size-h2: var(--font-size-base);
        --font-size: var(--font-size-xxsmall);
    }
}

.work {
    > div {
        align-items: flex-start;
        display: flex;
        justify-content: flex-start;
    }
    
    &__card {
        width: 100%;
    }

    p {
        margin: 0;
        max-width: var(--max-width);

        &:empty {
            display: none;
        }
    }

    .link {
        @include link-underline();
        color: var(--primary-color);
    }

    h2 {
        @include font-sans();
        max-width: var(--max-width);
        
        &:not(:last-child) {
            margin-bottom: space(2);
        }
        &:empty {
            display: none;
        }
    }

    &__media-wrapper {
        display: block;
        color: var(--white);
        margin-bottom: space(5.5);
        overflow: hidden;
        position: relative;
        width: 100%;

        .arrow {
            @include z-index($z-index-wrap);

            align-items: center;
            display: flex;
            height: 100%;
            justify-content: flex-start;
            position: absolute;
            top: 0;
            width: 50%;

            &--next {
                right: 0;
            }
            &--prev {
                left: 0;
            }
        }

        .thumbnail {
            @include z-index($z-index-3D);

            position: absolute;
            right: space(2);
            bottom: space(2);
        }
    }

    &__slider {
        display: flex;
        height: 100%;
        width: auto;
    }

    .slide {
        display: inline-flex;
        position: relative;
        width: 100%;

        > * {
            user-select: none;
            width: 100%;
        }

        img {
            height: 100%;
            object-fit: cover;
        }

        &:not(:first-child) {
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    &__categ {
        color: var(--grey);
        min-width: #{space(3)};
        position: relative;

        span {
            display: block;
            left: 0;
            position: absolute;
            transform-origin: top left;
            top: 0;
            transform: rotate(-90deg) translateX(-100%);
            white-space: nowrap;
        }
    }

    figure video {
        display: block;
        width: 100%;
    }
}

@media (min-width: $smartphone) {
    .work {
        column-gap: var(--padding-s);
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        > div {
            grid-column-start: var(--column-start);
            grid-column-end: var(--column-end);
        }

        &__media-wrapper {
            margin-bottom: space(6);
        }

        &__categ {
            min-width: #{space(4)};
        }
    }
}
