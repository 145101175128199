.block-highlight {
    @include font-sans();

    --font-size: var(--font-size-xxxlarge);
    --padding-v: var(--padding-xxl);
    --padding-h: #{securePaddingBig(space(20))};

    @media (max-width: $smartphone) {
        @include font-sans(1.145);

        --font-size: var(--font-size-base);
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-s);
    }
}

.block-highlight {
    font-size: var(--font-size);
    padding: var(--padding-v) var(--padding-h);

    > div {
        margin: 0 auto;
        text-align: center;
    }

    strong {
        font-weight: 400;
        position: relative;

        --stroke: 42%;

        background: linear-gradient(to right, transparent, transparent),
        linear-gradient(to right, var(--fluor), var(--fluor));
        background-repeat: no-repeat;
        transition: background-size 0.8s var(--ease-in-expo);
        
        background-size: 0 var(--stroke), 0 var(--stroke);
        
        &.animated {
            background-size: 0 var(--stroke), 100% var(--stroke);
        }

        .text {
            @include z-index($z-index-3D);
            position: relative;
        }
    }
}
