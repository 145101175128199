.form-interactive {
    --font-size-label: var(--font-size-xsmall);
    --font-size: var(--font-size-xsmall);
    --font-size-cta: var(--font-size-xxsmall);
    --font-size-legal: var(--font-size-xxsmall);
    --input-height: calc(var(--font-size) * 1.5);
    --color-success: var(--white);

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-small);
        --font-size-cta: var(--font-size-xxxsmall);
        --font-size-legal: var(--font-size-xxxxsmall);
    }
}

.form-interactive {
    font-size: var(--font-size);

    input,
    label,
    textarea,
    select,
    fieldset {
        border: 0;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
        outline: none;
        margin: 0;
        -webkit-appearance: none;
    }

    select,
    input {
        background: transparent;
        color: var(--secondary);
        cursor: pointer;

        &:not([type='checkbox']) {
            height: var(--input-height);
            line-height: var(--input-height);
        }
    }

    button {
        font-size: var(--font-size-cta);
    }

    label {
        color: var(--gray-300);
    }

    fieldset {
        margin-bottom: #{space(4)};
    }

    ::placeholder,
    :-ms-input-placeholder,
    ::-ms-input-placeholder {
        color: var(--gray-300);
    }

    .hidden {
        display: none;
    }

    &__footer {
        margin-top: #{space(10)};

        button {
            margin-top: 0;
        }
    }


    @media (max-width: $smartphone) {
        fieldset input {
            display: block;
            width: 100%;
        }
    }
}
