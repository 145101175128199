.billboard {
    --font-size-h1: var(--font-size-xxxxxxxlarge);
    --font-size-big: var(--font-size-xxxxlarge);
    --font-size: var(--font-size-base);
    --highlight-color: var(--fluor);

    --padding-top: calc(var(--header-height) + #{space(12)});
    --padding-bottom: #{space(8)};
    --padding-h: 0;

    @media (max-width: $smartphone) {
        --font-size-h1: var(--font-size-xlarge);
        --font-size-big: var(--font-size-base);
        --font-size: var(--font-size-small);
        --padding-top: calc(var(--header-height) + #{space(8)});
        --padding-bottom: #{space(4)};
        --padding-h: #{space(3)};
    }
}

.billboard {
    font-size: var(--font-size);
    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);

    .line-parent {
        overflow: hidden;
    }

    .headline {
        h1 {
            @include font-sans(1.3);
            font-size: var(--font-size-h1);
        }

        &:not(:last-child) {
            margin: 0 0 #{space(3)};
        }
    }

    .subtitle {
        @include font-sans();
        color: var(--gray-900);
        font-size: var(--font-size-big);

        &:not(:last-child) {
            margin: 0 0 #{space(4)};
        }
    }

    .text {
        @include font-sans();
        font-size: var(--font-size);
        margin: 0;

        p:not(:last-child) {
            margin-bottom: #{space(2.5)};
        }
    }

    @media (min-width: $smartphone) {
        @include grid-container();
        max-width: 100vw;

        > * {
            grid-column: 5 / 12;
            max-width: var(--max-width-box);
        }

        .headline {
            margin: 0 0 #{space(3.75)};
        }

        .subtitle {
            &:not(:last-child) {
                margin: 0 0 #{space(5.25)};
            }
        }

        .text {
            margin: 0;

            p:not(:last-child) {
                margin-bottom: #{space(2.5)};
            }
        }
    }
}
