#Contact {
    --font-size-big: var(--font-size-xxxxxlarge);
    --font-size: var(--font-size-xsmall);
    --padding-v: #{space(5)};
    --padding-h: #{securePadding(space(5))};

    @media (max-width: $smartphone) {
        --font-size-big: var(--font-size-large);
        --font-size: var(--font-size-xxsmall);
        --padding-v: #{space(5)};
        --padding-h: #{space(3)};
    }
}

#Contact {
    @include font-sans();
    @include z-index($z-index-wrap);

    height: 100%;
    position: absolute;
    transform: translate3d(0, 100%, #{$z-index-wrap}px);
    width: 100%;
    background-color: var(--white);

    .contact-wrapper {
        background-color: var(--white);
        color: var(--black);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        height: 100%;
        left: 0;
        padding: var(--padding-v) var(--padding-h);
        position: absolute;
        top: 0;
        width: 100%;

        &.ko,
        &.ok {
            @include z-index($z-index-3D);
            transform: translate3d(0, 100%, #{$z-index-3D}px);
        }
    }

    .close {
        @include z-index($z-index-wrap);
    
        --rot: 0deg;
        --fill: var(--black);
        --ease: var(--ease-in-out-quad);
    
        position: absolute;
        right: #{space(3)};
        top: #{space(3)};
        transform: rotate(var(--rot));
        transform-origin: center center;
        transition: transform 0.4s var(--ease);
    
        path {
            fill: var(--fill);
        }
    
        @include isCursor() {
            &:hover {
                --rot: 135deg;
                --fill: var(--fluor);
            }
        }
    }

    .cta-wrapper {
        align-items: center;
        display: flex;
        justify-content: flex-end;
    }

    .title,
    h3 {
        @include font-sans();

        display: inline-block;
        font-size: var(--font-size-big);
        margin-bottom: var(--padding-xs);
        position: relative;

        .text {
            @include z-index($z-index-3D);
            position: relative;
        }

        .highlight {
            @include z-index($z-index-bg);
            background-color: var(--fluor);
            height: 42%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    p { font-size: var(--font-size); }

    .form {
        margin-top: #{space(9)};
    }

    .cols {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    @media (max-width: $smartphone) {
        .contact-wrapper {
            padding-top: calc(var(--padding-v) + rem(30));
            justify-content: space-between;
        }

        fieldset { margin-bottom: 0; }

        .cols {
            flex-direction: column;
            height: 100%;
        }
    }

    @media (min-width: $smartphone) {
        .close {
            right: #{space(4)};
            top: #{space(4)};
        }

        .cols {
            > * { flex-basis: 45%; }
        }
    }
}