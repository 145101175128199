.block-clients {
    --font-size-title: var(--font-size-xxxxxlarge);
    --font-size-summary: var(--font-size-base);

    --padding-v: #{space(17)};
    --padding-h: #{securePadding(space(5))};

    --num-logos: 4;
    --gap: #{space(5)};
    --colStart: 5;
    --colEnd: 13;

    --margin-logos: #{space(9)};

    --color: var(--primary-color);

    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-large);
        --font-size-summary: var(--font-size-xxsmall);

        --padding-v: #{space(11)};
        --padding-h: var(--padding-s);
        --num-logos: 2;
        --gap: #{space(1)};

        --margin-logos: #{space(5)};

        --colStart: 1;
        --colEnd: 13;
    }
}

.block-clients {
    position: relative;
    width: 100vw;
    display: grid;

    grid-template-columns: repeat(12, 1fr);
    padding: var(--padding-v) var(--padding-h);

    @include font-sans();
    color: var(--primary-color);

    > .headline {
        grid-column: var(--colStart) / var(--colEnd);
        font-size: var(--font-size-title);
        font-weight: normal;
        margin-bottom: 0.63em;
        margin: 0;
    }

    > .body {
        grid-column: var(--colStart) / var(--colEnd);
        font-size: var(--font-size-summary);
    }
}

.block-clients__logos {
    grid-column: 1 / 15;
    display: grid;
    gap: var(--gap);
    margin-top: var(--margin-logos);
    grid-template-columns: repeat(var(--num-logos), 1fr);

    > figure.media-holder > img {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center center;
    }
}
