.block-legacy {
    --font-size-big: var(--font-size-xxxlarge);
    --font-size: var(--font-size-xlarge);
    --highlight-color: var(--fluor);

    --padding-v: var(--padding-xxxxxxl);
    --padding-h: #{securePadding(space(5))};
    --max-width: #{900px / 16px * 1rem};

    @media (max-width: $smartphone) {
        --font-size-big: var(--font-size-medium);
        --font-size: var(--font-size-base);
        --padding-v: var(--padding-xxxl);
        --padding-h: var(--padding-s);
        --max-width: none;
    }
}

.block-legacy {
    @include font-sans();

    background-color: var(--black);
    color: var(--white);
    font-size: var(--font-size);
    padding: var(--padding-v) var(--padding-h);
    position: relative;

    > div {
        p {
            font-size: var(--font-size);
            margin: 0 0 #{space(4)};
            max-width: var(--max-width);
        }

        a {
            @include link-stroke();

            color: var(--fluor);
            font-size: var(--font-size-big);
        }
    }
}

#overlay {
    @include z-index($z-index-overlay);
    background-color: var(--black);
}
