.next-project {
    --font-size: var(--font-size-base);
    --font-size-big: var(--font-size-xxxxxlarge);
    --font-summary: var(--font-size-medium);
    --max-width-summary: #{(750 / 16) * 1rem};
    --size-icon: #{(150 / 16) * 1rem};
    --padding-v: #{space(11)};
    --padding-h: #{securePadding(space(5))};
    --item-margin: #{space(3)};
    --margin-top: #{space(25)};

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xxsmall);
        --font-size-big: var(--font-size-base);
        --font-summary: var(--font-size-xxsmall);

        --size-icon: #{space(8)};
    
        --padding-v: #{space(8)};
        --padding-h: #{space(3)};
        --margin-top: #{space(13)};
    }
}

.next-project {
    --left-x:-1.5;
    --center-x:0;
    --right-x:0;

    @include font-sans();
    margin-top: var(--margin-top);

    p {
        margin: 0;
    }

    &__holder {
        @include basic-a();
        color: currentColor;
        background-color: var(--fluor);
        color: var(--black);
        display: block;
        padding: var(--padding-v) var(--padding-h);

        @include isCursor() {
            &:hover {
                --left-x:1;
                --center-x:1;
                --right-x:2.5;
            }
        }
    }

    .label {
        font-size: var(--font-size);
        margin-bottom: #{space()};
    }

    .summary {
        --padding: calc(var(--size-icon) * 1.2);

        font-size: var(--font-size);
        margin-top: #{space(.6)};
        max-width: calc(var(--max-width-summary) + var(--padding));
        padding-right: var(--padding);
    }

    .name {
        padding-right: calc(var(--size-icon) * 1.2);
        font-size: var(--font-size-big);
    }

    .icon {
        position: absolute;
        display: block;
        right: var(--padding-h);
        top: 50%;
        transform: translateY(-50%);
        width: var(--size-icon);
        height: var(--size-icon);
        overflow: hidden;
        
        svg {
            position: absolute;
            top:0;
            width: auto;
            height: 100%;
            fill: var(--black);
            transition: transform;
            transition-duration: .8s;
            transition-timing-function: var(--ease-in-out-quint);

            &.left { 
                left:-33.33%;
                transform: translateX(calc(var(--left-x) * 50%));
            }

            &.center {
                left:0;
                transform: translateX(calc(var(--center-x) * 50%));
            }

            &.right {
                left:33.33%;
                transform: translateX(calc(var(--right-x) * 50%));
            }
        }
    }

    @media (max-width: $smartphone) {
        &__holder {
            overflow: hidden;
        }

        .icon {
            align-items: center;
            display: flex;
            justify-content: center;

            svg {
                &.left { 
                    display: none;
                }
            }
        }
    }

    // @media (min-width: $smartphone) {
    //     .icon {
    //         //display: none;
    //     }
    // }
}
