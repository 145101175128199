#Preloader {
    --bg: var(--fluor);

    &.--black {
        --bg: var(--black);
    }

    @include z-index($z-index-preloader);
    position: fixed;
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    > .bg {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: var(--bg);
    }

    > .images {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        opacity: 0;

        img {
            position: absolute;
            opacity: 0;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
}