.block-futures-list {
    --font-size: var(--font-size-large);
    --padding-v: var(--padding-xxxxxxl);
    --padding-h: #{securePadding(space(4))};
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-base);
        --padding-v: var(--padding-xxxl);
        --padding-h: var(--padding-s);
    }
}

.block-futures-list {
    @include font-sans();

    &__categories {
        padding: 0 var(--padding-h);
        font-size: var(--font-size);

        > div {
            button {
                color: var(--gray-700);
                position: relative;

                &.--active {
                    color: var(--black);
                    pointer-events: none;
                    cursor: pointer;
                }

                sup {
                    position: absolute;
                    right: #{space(-2.5)};
                    top: #{space(0.75)};
                    transform: scale3d(0.8, 0.8, 0.8);
                }
            }

            li:not(:last-child) {
                margin-bottom: #{space(3)};
            }

            @include isCursor() {
                button:hover {
                    color: var(--black);
                }
            }
        }
    }

    &__wrapper {
        padding: var(--padding-v) var(--padding-h);
        position: relative;
    }

    .future {
        &.--disabled {
            display: none;
        }
    }

    @media (max-width: $smartphone) {
        &__categories {

            > div ul:first-child {
                margin-bottom: #{space(3)};
            }
        }
    }

    @media (min-width: $smartphone) {
        &__categories {
            display: grid;
            @include grid-container();
            
            > div {
                display: flex;
                grid-column: 5 / 12;
            }
        }

        &__wrapper {
            ul {
                display: grid;
                gap: #{space(3)};
                grid-template-columns: repeat(3, 1fr);
                grid-auto-rows: rem(440);
            }

            li {
                grid-column: span var(--cols);
                grid-row: span var(--rows);
            }
        }

        .future {
            &.--disabled {
                pointer-events: none;
            }
        }
    }
}