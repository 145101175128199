.block-works {
    --padding-top: var(--padding-l);
    --padding-bottom: #{space(25)};
    --padding-h: var(--padding-l);
    --work-margin: #{space(15)};

    @media (max-width: $smartphone) {
        --padding-top: var(--padding-s);
        --padding-bottom: #{space(13)};
        --padding-h: var(--padding-s);
        --work-margin: #{space(12)};
    }
}

.block-works {
    @include font-sans();

    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);

    .work {
        margin-bottom: var(--work-margin);
      
        &:last-child { margin-bottom: 0; }
    }
}
