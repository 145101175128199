.block-media-post {
    --padding-v: #{space(5)};
    --padding-h: #{securePadding(space(4))};

    @media (max-width: $smartphone) {
        --padding-v: #{space(3)};
        --padding-h: #{space(3)};
    }
}

.block-media-post {
    margin: var(--padding-v) 0;
    padding: 0 var(--padding-h);

    &__caption {
        @include font-sans();
        font-size: var(--font-size);
        margin-top: #{space(2)};
    }

    @media (min-width: $smartphone) {
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        &__caption {
            margin-top: #{space(3)};
        }

        > * {
            grid-column: 5 / 13;
        }
    }
}
