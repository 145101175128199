.block-reel-post {
    --font-size: var(--font-size-small);
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-l);

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xxsmall);
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-s);
    }
}

.block-reel-post {
    margin: #{space(3)} 0;
    padding: var(--padding-v) var(--padding-h);

    .media-holder {
        @include z-index($z-index-bg);

        height: 100%;
        left: 0;
        padding: 0;
        position: absolute;
        object-fit: cover;
        top: 0;
        width: 100%;

        img {
            object-fit: cover;
        }
    }

    &__caption {
        @include font-sans();
        font-size: var(--font-size);
        margin-top: #{space(2)};
    }

    &__wrapper {
        @include aspect-ratio-img(16, 9);

        position: relative;
    }

    &__layer,
    &__layer-2 {
        @include z-index($z-index-player);
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        width: 100%;
    }

    &__layer-2 { display: none; }

    @media (min-width: $smartphone) {
        margin: #{space(5)} 0;

        &__caption {
            margin-top: #{space(3)};
        }
    }

    .plaver-video-full {
        --font-size-timer: 20.5vw;
        --color: var(--white);
        --interface-height: var(--header-height);

        @include z-index($z-index-player);
        @include aspect-ratio-img(16, 9);

        * {
            cursor: none;
        }

        background: var(--black);
        opacity: 0;
        position: absolute;
        top: 0;

        > video {
            object-fit: cover;
            object-position: center center;
        }
    }

    //TIMER
    .plaver-video-full__timer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include font-sans(1);

        font-size: var(--font-size-timer);
        color: var(--color);
        background-color: rgba(0, 0, 0, 0.45);
    }

    .plaver-video-full__interface {
        height: var(--interface-height);
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 var(--padding-m);

        display: flex;
        align-items: center;
        justify-content: center;

        > span {
            flex: 0;
            @include font-sans(1);
            font-size: var(--font-size-base);
            color: var(--color);
        }

        > .plaver-video-full__progress {
            flex: 1;
        }
    }

    //PROGRESS
    .plaver-video-full__progress {
        cursor: pointer;
        height: 8px;
        position: relative;

        &:before {
            @include pseudo-element-absolute();
            top: -40px;
            width: 100%;
            height: calc(100% + 80px);
        }

        > .track,
        > .thumb {
            position: absolute;
            top: rem(-4);
            left: 0;
            width: 100%;
            height: rem(15);
        }

        > .thumb {
            background: var(--fluor);
            transform: scaleX(0);
            transform-origin: 0 0;
        }

        > .track {
            background-color: var(--color);
            opacity: 0.4;
        }
    }

    .plaver-video-full__controls {
        flex: 0 0 80px;
        height: var(--interface-height);

        &.--play {
            .__play,
            .__muted {
                display: none;
            }
            .__pause {
                display: block;
            }
        }

        &.--muted {
            .__play,
            .__pause {
                display: none;
            }
            .__muted {
                display: block;
            }
        }

        &.--pause {
            .__pause,
            .__muted {
                display: none;
            }
            .__play {
                display: block;
            }
        }
    }

    .plaver-video-full__controls > button {
        position: relative;

        width: 100%;
        height: 100%;
        background: transparent;
        padding: 0;
        border: none;

        @include basic-a();
        @include font-sans(1);

        font-size: var(--font-size-base);
        color: var(--color);
    }

    ///SHOW
    .plaver-video-full {
        cursor: none;

        * {
            cursor: none;
        }

        > div {
            opacity: 0;
        }

        .__showInterface & {
            > div {
                opacity: 1;
            }

            cursor: auto;

            * {
                cursor: auto;
            }
        }

        .__showInterfaceForce & {
            > div {
                opacity: 1;
            }

            cursor: auto;

            * {
                cursor: auto;
            }
        }
    }

    @media (max-width: $smartphone) {
        .plaver-video-full {
            --interface-height: #{rem(40)};
        }

        .plaver-video-full__controls {
            flex: 0 0 180px;
            display: flex;
        }

        .plaver-video-full__controls > button {
            width: 60px;

            &.__full {
                width: 120px;
            }
        }

        .plaver-video-full {
            height: calc(var(--vh, 1vh) * 100);

            > .plaver-video-full__interface {
                opacity: 1;
            }
        }

        .plaver-video-full__interface {
            padding: 0 var(--padding-xs);
        }
    }
}
