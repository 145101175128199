.block-new-bussines {
    --font-size-h1: var(--font-size-xxxxxxxlarge);
    --font-size-ok: var(--font-size-xxxlarge);
    --font-size: var(--font-size-xxsmall);
    --padding-top: calc(var(--header-height) + #{space(12)});
    --padding-bottom: #{space(32)};
    --padding-h: #{securePadding(space(4))};
    
    @media (max-width: $smartphone) {
        --font-size-h1: var(--font-size-xlarge);
        --font-size-ok: var(--font-size-small);
        --font-size: var(--font-size-xxxsmall);
        --padding-top: calc(var(--header-height) + #{space(8)});
        --padding-bottom: #{space(14)};
        --padding-h: #{space(3)};
    }

    min-height: 100vh;
}

.block-new-bussines {
    color: var(--secondary);
    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);

    &__wrapper {
        position: relative;
    }
    
    .line-parent {
        overflow: hidden;
    }

    .ok,
    .ko {
        @include z-index($z-index-3D);

        background-color: var(--primary);
        color: var(--fluor);
        font-size: var(--font-size-ok);
        height: 110%;
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        visibility: hidden;
        width: 100%;
    }

    .form-interactive,
    .ok,
    .ko {
        @include font-sans();
    }

    .form {
        &__footer {
            justify-content: flex-start;
        }

        fieldset.--checkbox {
            margin-top: #{space(11)};
        }
    }

    .custom-checkbox {
        --color: var(--secondary);  
    }

    @media (min-width: $smartphone) {
        &__wrapper {
            display: grid;
            grid-template-columns: repeat(12, 1fr);

            .--hidden {
                display: none;
            }

            transition: opacity .4s ease-out;
            .--hide {
                opacity: 0;
            }
        }

        .form-interactive {
            grid-column: 4 / 11;
        }

        .ok,
        .ko {
            grid-column: 4 / 12;
        }

        .form-interactive {
            fieldset {
                &.--checkbox {
                    margin-top: #{space(7.5)};
                    width: 100%;
                }
            }
        }
    }
}

@keyframes promt {
    0% { color: var(--black); }
    50% { color: var(--white); }
    100% { color: var(--white); }
  }
  

.block-new-bussines__log {
    grid-column: 4 / 11;
    color: var(--gray-500);
    @include font-sans();
    font-size: var(--font-size-ok);
    
    br {
        content: "";
        margin-bottom: .8em;
        display: block;
    }

    > div {
        margin-bottom: .6em;
    }

    > i {
        --stroke: max(1px, 0.06em); 
        margin-right: .25em;
        font-style: normal;
        background: 
            linear-gradient(to right, transparent, transparent),
            linear-gradient(to right, currentColor, currentColor);
        background-size: 0 var(--stroke), 100% var(--stroke);
        background-position: 100% calc(100%), 0 calc(100%);
        background-repeat: no-repeat;
        cursor: pointer;

        &:hover {
            color: white;
        }

        &.--input {
            color: white;
            background: transparent;
        }

        &.--input.--error {
            color: var(--color-error);
        }

        &.--input.--active {
            &::after {
                display: inline-block;
                content: '|';
                width: 1.5em;
                height: auto;
                animation-name: promt;
                animation-duration: .6s;
                animation-iteration-count: infinite;
                background: url("http://localhost:8080/assets/svg/tick.svg") no-repeat;
                background-position: top right;
                background-size: contain;
            }
        }
    }

    > span {
        margin-right: .25em;
    }

    > p {
        margin: 0;
        color: var(--white);
        margin-right: .25em;
    }
}
