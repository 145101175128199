//↗ → ← ↖
@mixin link-stroke() {
    --from: left;
    --to: right;
    --stroke: max(1px, 0.08em);

    .__mouse-left & {
        --from: right;
        --to: left;
    }

    &.is-active,
    &[aria-current='page'] {
        pointer-events: none;

        &::after {
            transform: scale3d(1,1,1);
        }
    }

    @include basic-a();
    position: relative;
    text-decoration: none;
    color: inherit;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: var(--stroke);
        background-color: currentColor;

        transform: scale3d(0,1,1);
        transition: transform .4s var(--ease-out-quad);
        transform-origin: var(--to);
    }

    @include isCursor() {
        &:hover::after {
            transform: scale3d(1,1,1);
            transition: transform .2s;
            transform-origin: var(--from);
        }
    }
}

.link-stroke {
    @include link-stroke();
}
