#Interface__Canvas {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    @include z-index($z-index-player);
    pointer-events: none;
}

body > canvas {
    @include z-index(0);
    position: fixed;
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;
}
