:root {
    --font-sans: 'Neuzeit S LT Std', sans-serif;
    --font-sans-bold: 'Neuzeit S LT Std', sans-serif;

    --font-size-giant: #{(600px / 16px) * 1rem};
    --font-size-giant-l: #{(320px / 16px) * 1rem};
    --font-size-giant-m: #{(240px / 16px) * 1rem};
    --font-size-giant-xm: #{(144px / 16px) * 1rem};
    --font-size-giant-s: #{(200px / 16px) * 1rem};
    --font-size-giant-xs: #{(115px / 16px) * 1rem};
    --font-size-higlightlarge: #{(100px / 16px) * 1rem};
    --font-size-highlight: #{(92px / 16px) * 1rem};
    --font-size-xxxxxxxlarge: #{(76px / 16px) * 1rem};
    --font-size-xxxxxxlarge: #{(68px / 16px) * 1rem};
    --font-size-xxxxxlarge: #{(60px / 16px) * 1rem};
    --font-size-xxxxlarge: #{(54px / 16px) * 1rem};
    --font-size-xxxlarge: #{(48px / 16px) * 1rem};
    --font-size-xxlarge: #{(42px / 16px) * 1rem};
    --font-size-xlarge: #{(36px / 16px) * 1rem};
    --font-size-large: #{(32px / 16px) * 1rem};
    --font-size-medium: #{(28px / 16px) * 1rem};
    --font-size-base: #{(24px / 16px) * 1rem};
    --font-size-small: #{(20px / 16px) * 1rem};
    --font-size-xsmall: #{(18px / 16px) * 1rem};
    --font-size-xxsmall: #{(16px / 16px) * 1rem};
    --font-size-xxxsmall: #{(14px / 16px) * 1rem};
    --font-size-xxxxsmall: #{(12px / 16px) * 1rem};
    --font-size-xxxxxsmall: #{(9px / 16px) * 1rem};

    --line-height-sans: 1.2;
    --letter-spacing: 0;

    --max-font-size: 20px;
    --width-offset: calc((1440px * 20)/16);

    font-size: min(1.1111vw, var(--max-font-size));
    --size-offset: 0px;
    --size-offset-big: calc((100vw - var(--width-offset))/2 - var(--size-offset));

    @media (max-width: $smartphone) {
        font-size: 16px;
    }
}

@mixin font-sans($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: normal;
    @if $line-height > 0 {
        line-height: $line-height;
    } @else {
        line-height: var(--line-height-sans);
    }
}

@mixin font-sans-bold($line-height: 0) {
    font-family: var(--font-sans-bold);
    font-weight: 900;
    @if $line-height > 0 {
        line-height: $line-height;
    } @else {
        line-height: var(--line-height-sans);
    }
}
