.header-block {
    --font-size-header-title: var(--font-size-xxxxxlarge);
    --font-size-header: var(--font-size-base);

    &:not(.--grid-extended) {
        --col0: 5;
        --col1: 12;
    }
    
    &.--grid-extended {
        --col0: 6;
        --col1: 13;
    }    
    
    @media (max-width: $smartphone) {
        --font-size-header-title: var(--font-size-large);
        --font-size-header: var(--font-size-xxsmall);
       
        &:not(.--grid-extended) {
            --col0: 1;
            --col1: 13;
        }
        
        &.--grid-extended {
            --col0: 2;
            --col1: 14;
        }    
    }
}

.header-block {
    grid-column: var(--col0) / var(--col1);

    > .headline {
        font-size: var(--font-size-header-title);
        max-width: var(--max-width-box);
        font-weight: normal;
        margin: 0 0 .63em;
    }

    > .body {
        max-width: var(--max-width-box);
        font-size: var(--font-size-header);
    }   
}
