.block-slider-text {
    --font-size-counter: var(--font-size-giant-l);
    --font-size-title: var(--font-size-higlightlarge);
    --font-size-body: var(--font-size-base);

    --max-width-body: #{(900px / 16px) * 1rem};

    --padding-top: #{space(20)};
    --padding-bottom: #{space(4)};
    --padding-h: var(--padding-l);

    --color-highlight: var(--fluor);
    
    .palette-fluor & {
        --color-highlight: var(--black);
    }

    .palette-grey & {
        --color-highlight: var(--fluor);
    }

    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-large);
        --font-size-body: var(--font-size-xxsmall);
        --font-size-counter: var(--font-size-giant-xm);

        --padding-top: #{space(8)};
        --padding-bottom: #{space(4)};
        --padding-h: var(--padding-s);
    }
}

@mixin touchstyles {
    margin-bottom: #{space(4)};

    > div {
        height: 100%;
        width: auto;
        overflow: hidden;

        >.counter,
        >.title {
            display: none;
        }
    }

    .holder {
        -webkit-overflow-scrolling: touch;
        overflow: hidden;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .slide {
        min-width: 80vw;

        &:first-child {
            display: none;
        }

        scroll-snap-align: start;

        .counter {
            @include font-sans(1);

            margin-top: #{space(4)};
        }

        > div {
            justify-content: flex-end;
        }
    }
}

@mixin cursorstyles {
    height: var(--height);
    
    > div {
        height: 100vh;
        position: relative;
        width: 100vw;
        overflow: hidden;
    }
    
    .slide {
        padding-bottom: calc(var(--font-size-counter) + var(--padding-bottom) + #{space(4)});
    
        .counter,
        .title {
            display: none;
        }

        > div {
            justify-content: flex-end;
        }
    }
    
    .counter {
        bottom: #{space(2)};
        left: var(--padding-h);
        position: absolute;
        overflow: hidden;
    }
    
    .holder {
        left: 0;
        position: absolute;
        top: 0;
    }
}

.block-slider-text {
    position: relative;
    width: 100vw;
    overflow: hidden;

    @include font-sans();
    color: var(--primary-color);

    .holder {
        display: flex;
        height: 100%;
        width: auto;
    }

    .counter {
        display: flex;

        @include font-sans();
        @include z-index($z-index-3D);

        font-size: var(--font-size-counter);
        color: var(--color-highlight);
        transition: color .1s ease-out;

        .number {
            height: var(--font-size-counter);

            &:first-child {
                transition: transform 0.5s var(--ease-in-out-quart) 0.2s;
            }

            &:last-child {
                transition: transform 0.5s var(--ease-in-out-quart);
            }

            span {
                display: block;
                height: var(--font-size-counter);
            }

            &:first-child {
                transform: translate3d(0, var(--tens), 0);
            }

            &:last-child {
                transform: translate3d(0, var(--units), 0);
            }
        }
    }

    .titles {
        @include z-index($z-index-3D);

        bottom: #{space(5.5)};
        left: #{space(52)};
        position: absolute;
        overflow: hidden;

        .titles-wrapper {
            height: calc(var(--font-size-title) * 1.25);
            margin-bottom: #{space()};
            overflow: hidden;

            &:first-child .title {
                transition: transform 0.5s var(--ease-in-out-quart);
            }
                        
            &:last-child .title {
                transition: transform 0.5s var(--ease-in-out-quart) 0.05s;
            }
        }
        
        .title {
            height: calc(var(--font-size-title) * 1.25);
            padding-top: rem(5);
            transform: translate3d(0, var(--units), 0);
        }
    }

    .title {
        @include font-sans(1.4);
    
        font-size: var(--font-size-title);
        transition: color .1s ease-out;
    
        .light {
            color: var(--color-highlight);
        }
    
        .dark {
            color: var(--black);
        }
    
        > span {
            display: block;
        }
    }

    .slide {
        background: transparent;
        padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
        width: 100vw;

        > div {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
        }

        .body {
            max-width: var(--max-width-body);

            @include font-sans();
            font-size: var(--font-size-body);
            color: var(--white);
        }
    }

    .__touch & {
        @include touchstyles;
    }
    
    .__cursor & {
        @include cursorstyles;
    }
}
