.block-404 {
    --font-size-title: var(--font-size-giant);
    --font-size-summary: var(--font-size-xxlarge);
    --font-size: var(--font-size-xsmall);
    --padding-top: calc(var(--header-height) + #{space(12)});
    --padding-bottom: #{space(8)};
    --padding-h: #{space(4)};
    --marginSummary: 2em;
    --colTitleStart: 3;
    --colTitleEnd: 11;
    --colStart: 5;
    --colEnd: 11;

    @media (max-width: $smartphone) {
        --padding-top: calc(var(--header-height) + #{space(8)});
        --padding-bottom: #{space(4)};
        --padding-h: #{space(3)};
        --marginSummary: 3em;
        --font-size-title: var(--font-size-giant-s);
        --font-size-summary: var(--font-size-medium);
        --font-size: var(--font-size-xxsmall);
        --colTitleStart: 1;
        --colTitleEnd: 12;
        --colStart: 1;
        --colEnd: 12;
    }
}

.block-404 {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);

    @include font-sans();

    > .title {
        position: relative;

        @include aspect-ratio(959, 460);

        grid-column: var(--colTitleStart) / var(--colEnd);
        font-size: var(--font-size-title);
        text-align: center;
        background-color: var(--fluor);
        line-height: 1;
        overflow: hidden;

        &:before,
        &:after {
            @include z-index(2);
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: var(--primary-bg);
        }

        &:before {
            top: 0;
        }

        &:after {
            bottom: 0;
        }

        > svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: scale(1.3);

            ellipse,
            polygon,
            path {
                fill: var(--primary-bg);
            }
        }

        video {
            position: absolute;
            top: 1px;
            left: 0;
            width: 100%;
            height: calc(100% - 2px);
            object-fit: cover;
            object-position: center;
            transition: opacity 0.2s ease-in;
        }

        span {
            position: absolute;
            top: 1px;
            left: 0;
            width: 100%;
            height: calc(100% - 2px);

            &:before,
            &:after {
                position: absolute;
                content: '';
                @include z-index(2);
                top: 0;
                height: 100%;
                width: 1px;
                background-color: var(--primary-bg);
            }

            &:before {
                left: 0;
            }

            &:after {
                right: 0;
            }
        }

        &:hover {
            > video {
                opacity: 1;
                transition: opacity 0.2s ease-out;
            }
        }
    }

    > .summary {
        grid-column: var(--colStart) / var(--colEnd);
        font-size: var(--font-size-summary);
        margin: var(--marginSummary) 0 0;
    }

    > p {
        grid-column: var(--colStart) / var(--colEnd);
        font-size: var(--font-size);
        margin: 1em 0;
    }

    a {
        @include link-underline();
        color: currentColor;
    }
}
