#Footer {
    --font-size-big: var(--font-size-higlightlarge);
    --font-size: var(--font-size-xsmall);
    --padding-v: #{space(4)};
    --padding-h: #{securePadding(space(5))};
    --color: var(--black);
    --bg: var(--grey);
    --cta: var(--fluor);

    @media (max-width: $smartphone) {
        --font-size-big: var(--font-size-xxxxlarge);
        --font-size: var(--font-size-xxxsmall);
        --padding-v: #{space(4)};
        --padding-h: #{space(3)};
    }

    &.hover {
        --color: var(--fluor);
        --bg: var(--fluor);
        --cta: var(--black);
    }
}

#Footer {
    @include font-sans();

    overflow: hidden;

    .wrapper {
        @include z-index($z-index-3D);
        background-color: var(--bg);
        color: var(--color);
        display: flex;
        flex-direction: column;
        padding: var(--padding-v) var(--padding-h);

        > div:first-child {
            text-align: center;
            width: 100%;
        }

        .cta {
            @include z-index($z-index-3D);
            @include link-stroke();

            color: var(--cta);
            font-size: var(--font-size-big);
            position: relative;
        }

        > nav a {
            @include font-sans(1.2);

            color: inherit;
            font-size: var(--font-size);
        }

        .langs {
            a {
                &:not(:last-child) {
                    margin-right: var(--padding-m);
                }

                text-transform: uppercase;
            }
        }
        
        .langs,
        .rrss,
        .legals,
        .contact {
            display: flex;
            align-items: flex-start;

            > *:not(:last-child) {
                margin-right: var(--padding-m);
            }
        }

        .copyright {
            display: flex;
            flex-direction: column;
        }
    }

    @include isCursor() {
        &.hover .cta {
            transform: scale3d(1.25, 1.25, 1) translate3d(0, 0, #{$z-index-3D}px);
        }
    }

    @media (max-width: $smartphone) {
        a {
            text-decoration: underline;
        }
    
        .wrapper {
            align-items: flex-start;
            justify-content: space-between;

            .rrss {
                margin-bottom: #{space(5)};
            }

            .langs {
                margin-top: #{space(5)};
                margin-bottom: #{space(3)};

                .is-active {
                    text-decoration: none;
                    font-weight: bold;
                    pointer-events: none;
                }
            }

            .cta {
                margin-bottom: #{space(9)};
            }

            > nav {
                > div:not(:last-child) {
                    margin-bottom: #{space(5)};
                }
            }

            .contact {
                display: flex;
                flex-direction: column;

                a:first-child {
                    margin-bottom: #{space(3)};
                }
            }
        }

        .copyright {
            margin-top: #{space(5)};
            
            p:first-child {
                margin-bottom: #{space(1.5)};
            }
        }
    }

    @media (min-width: $smartphone) {
        height: 250px;

        .wrapper {
            align-items: center;
            justify-content: center;
            height: 100%;
            padding-top: var(--padding-v);

            .langs,
            .rrss,
            .legals,
            .contact {
                >*:not(:last-child) {
                    margin-right: var(--padding-xs);
                }
            }

            .langs {
                a {
                    &:not(:last-child) {
                        margin-right: var(--padding-xs);
                    }
                }
            }

            > nav {
                bottom: var(--padding-v);
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                left: var(--padding-h);
                position: absolute;
                right: var(--padding-h);
                top: var(--padding-v);

                > div {
                    align-items: flex-end;
                    display: flex;
                    justify-content: space-between;
                }

                a {
                    @include link-stroke();
                }
            }
        }

        .footer-bottom {
            display: flex;
            flex-direction: row-reverse;
        }
        
        .langs {
            justify-content: flex-end;
            width: 100%;
        }

        .copyright p:first-child,
        .langs {
            margin-bottom: #{space(2)};
        }
    }

    @media (max-width: 1023px) {
        .wrapper {
            transform: none !important;
        }
    }

    @media (min-width: $tabletLandscape) {
        --height: 50vh;
        height: var(--height);
    
        .wrapper {
            height: calc(var(--height) * (1 + var(--speed)));
            padding-top: calc(var(--height) / 5 * (1 + var(--speed)) + var(--padding-v));

            > nav {
                top: calc(var(--height) / 5 * (1 + var(--speed)) + var(--padding-v));

            }
        }
    }
}
