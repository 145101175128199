.block-acordions {
    --font-size-title: var(--font-size-xxxxxlarge);
    --font-size-body: var(--font-size-base);

    --padding-v: #{space(16)};
    --padding-h: #{securePadding(space(5))};
    --margin: #{space(13)};
    --padding-acirdions: var(--padding-xxxxxl);
    --max-width-body: #{(1172px / 16px) * 1rem};

    --col0: 1;
    --col1: 13;

    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-large);
        --font-size-body: var(--font-size-xxsmall);

        --padding-v: #{space(10)};
        --padding-h: var(--padding-s);
        --margin: #{space(6)};
        --padding-acirdions: 0;
    }
}

.block-acordions {
    position: relative;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: var(--padding-v) var(--padding-h);

    @include font-sans();
    color: var(--primary-color);
}

.block-acordions__ul {
    grid-column: var(--col0) / var(--col1);

    list-style: none;
    margin: var(--margin) 0 0;
    padding: 0 var(--padding-acirdions);

    > li {
        border-top: 1px solid currentColor;

        &:last-child {
            border-bottom: 1px solid currentColor;
        }
    }

    //ACORDEON
    article {
        --duration: 0.4s;
        --ease: var(--ease-in-out-quad);
    }

    article > button {
        --rot: 0deg;
        --strokeCross: #{(4px / 16px) * 1rem};

        position: relative;
        @include basic-a();
        width: 100%;
        padding: var(--padding-m) 0 calc(var(--padding-m) - 0.1em);
        font-size: var(--font-size-title);
        line-height: 1;
        text-align: left;

        @include isCursor() {
            transition: padding-left .2s ease-in-out;

            &:hover {
                color: var(--fluor);
                padding-left: #{space(2)};
            }
        }

        > span {
            position: absolute;
            right: 0.1em;
            top: 50%;
            width: var(--padding-m);
            height: var(--padding-m);

            transform-origin: 50% 50%;
            transform: translate3d(0, -50%, 0) rotate(var(--rot));

            transition: transform 0.5s var(--ease);

            &::after,
            &::before {
                content: '';
                position: absolute;
            }

            &::after {
                top: 0;
                left: 50%;
                width: var(--strokeCross);
                height: 100%;
                transform: translateX(-50%);
                background: currentColor;
            }

            &::before {
                top: 50%;
                left: 0;
                width: 100%;
                height: var(--strokeCross);
                transform: translateY(-50%);
                background: currentColor;
            }
        }
    }

    article[aria-expanded='true'] > button {
        --rot: 135deg;
        
        color: var(--fluor);
    }

    article > div {
        @include block-default();
        max-width: var(--max-width-body);
        padding-bottom: var(--padding-xxl);
    }
}
