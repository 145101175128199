.block-text-post {
    --font-size: var(--font-size-base);
    --padding-v: #{space(5)};
    --padding-h: #{securePadding(space(5))};

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xxsmall);
        --padding-v: #{space(3)};
        --padding-h: #{space(3)};
    }
}

.block-text-post {
    @include font-sans();

    font-size: var(--font-size);
    margin: var(--padding-v) 0;
    padding: 0 var(--padding-h);

    p {
        margin: 0 0 #{space(3)};
    }

    a {
        @include link-stroke();
        color: var(--fluor-link);
    }

    ul li {
        padding-left: #{space(2.5)};
        position: relative;

        &:before {
            content: '·';
            left: 0;
            position: absolute;
        }

        &:not(:last-child) {
            margin-bottom: #{space()};
        }
    }

    @media (min-width: $smartphone) {
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        > * {
            grid-column: 5 / 13;
        }
    }
}
