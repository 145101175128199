.billboard-post {
    --font-size-h1: var(--font-size-xxxlarge);
    --font-size-big: var(--font-size-xxxlarge);
    --font-size: var(--font-size-large);
    --font-size-label: var(--font-size-base);
    --highlight-color: var(--fluor);

    --padding-top: calc(var(--header-height) + #{space(6.5)});
    --padding-bottom: #{space(8)};
    --padding-h: #{securePadding(space(5))};

    @media (max-width: $smartphone) {
        --font-size-h1: var(--font-size-xlarge);
        --font-size-big: var(--font-size-xxsmall);
        --font-size: var(--font-size-base);
        --font-size-label: var(--font-size-xxsmall);
        --padding-top: calc(var(--header-height) + #{space(4)});
        --padding-bottom: #{space(4)};
        --padding-h: #{space(3)};
    }
}

.billboard-post {
    @include font-sans();

    color: var(--gray-900);
    font-size: var(--font-size);
    min-height: 80vh;
    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);

    .line-parent {
        overflow: hidden;
    }
    
    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .info {
        font-size: var(--font-size-label);
    }

    .headline {
        h1 {
            @include font-sans(1.3);
            font-size: var(--font-size-h1);
        }

        color: var(--secondary);
        margin: #{space()} 0 #{space(2)};
    }

    .subtitle {
        @include font-sans();
        font-size: var(--font-size-big);
        margin: 0 0 #{space(4)};
    }

    .author {
        @include font-sans();
        font-size: var(--font-size);
        color: var(--white);
        margin: 0;
    }

    .bottom {
        align-items: center;    
        display: flex;
        justify-content: space-between;
    }

    .spotify {
        @include basic-a();

        height: #{space(7)};
        width: #{space(7)};

        svg {
            height: 100%;
            width: 100%;
        }
    }

    @media (min-width: $smartphone) {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        min-height: 100vh;

        > * {
            grid-column: 3 / 13;
        }

        .headline {
            margin: #{space(2)} 0 #{space(3)};
        }

        .subtitle {
            margin: 0 0 #{space(5.25)};
        }
    }
}
