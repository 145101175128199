.future {
    --font-size-title: var(--font-size-xxxlarge);
    --font-size-subtitle: var(--font-size-base);
    --font-size: var(--font-size-large);
    --max-width: #{(600 / 16) * 1rem};

    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-base);
        --font-size: var(--font-size-xxsmall);
    }
}

.future {
    position: relative;
    overflow: hidden;

    .line-parent {
        overflow: hidden;
    }

    a,
    &__overlay,
    &__content,
    .media-holder {
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    a {
        @include basic-a();

        align-items: center;
        background-color: var(--primary);
        color: var(--secondary);
        display: flex;
        font-size: var(--font-size);
        height: 100%;
        justify-content: space-between;
        text-decoration: none;

        > .future__content {
            background-color: var(--primary);
            color: var(--secondary);
        }
    }

    .media-holder {
        @include z-index($z-index-bg);

        height: 100%;
        padding: 0;
        transform: scale3d(1.01, 1.01, 1);
        transition: transform 0.3s ease-out;

        img {
            height: 100%;
            object-fit: cover;
        }
    }

    &__overlay {
        @include z-index($z-index-wrap);
        
        background-color: var(--fluor);
        height: 100%;
        transform: translate3d(0, 100%, 0);
        transition: transform 0.3s var(--ease-in-out-quart);
        
        .date,
        .subtitle {
            opacity: 0;
            transition: opacity 0.1s ease-out;
        }
    }

    .subtitle {
        max-width: var(--max-width);
    }
    
    &__content {
        @include z-index($z-index-3D);

        align-items: flex-start;
        bottom: 0;
        color: var(--primary);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 30%;
        padding: #{space(2)};
        top: auto;

        .date {
            color: var(--gray-900);
            font-size: var(--font-size);
            margin-bottom: #{space()};
        }
        
        .title {
            font-size: var(--font-size-title);

            &:not(:last-child) {
                margin-bottom: #{space(2)};
            }
        }
        
        .subtitle {
            font-size: var(--font-size-subtitle);
        }
    }

    @include isCursor () {
        &:hover {
            .media-holder {
                transform: scale3d(1.25, 1.25, 1);
                transition: transform .6s ease-out;
            }

            .future__overlay {
                transform: translate3d(0, 0, 0);
                transition: transform 0.5s var(--ease-in-out-quart);
                
                .date,
                .subtitle {
                    opacity: 1;
                    transition: opacity 0.4s ease-out 0.6s;
                }
            }
        } 
    }

    @media (max-width: $smartphone) {   
        margin-bottom: #{space(3)};
        padding-bottom: 100%;
    }

    @media (min-width: $smartphone) {   
        &__content {
            padding: #{space(4)};

            .date {
                margin-bottom: #{space(2)};
            }
        }
    }
}