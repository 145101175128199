.block-reel {
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-l);

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-s);
    }
}

.block-reel {
    

    @media (min-width: $smartphone) {
        @include aspect-ratio-img(16, 9);
    }

    @media (max-width: $smartphone) {
        @include aspect-ratio-img(4, 3);
    }

    margin: var(--padding-h) 0;
    position: relative;

    .media-holder {
        @include z-index($z-index-bg);

        height: 100%;
        left: 0;
        padding: 0;
        position: absolute;
        object-fit: cover;
        top: 0;
        width: 100%;

        video,
        img {
            object-fit: cover;
            height: 100%;
            object-position: center;
        }
    }

    &__wrapper {
        background-color: var(--fluor);
        left: 50%;
        position: absolute;
        height: calc(100% - var(--padding-h) * 2);
        transform: translate3d(-50%, -50%, 0);
        top: 50%;
        width: calc(100% - var(--padding-v) * 2);
    }

    &__layer {
        @include z-index($z-index-player);

        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        width: 100%;
    }
}
