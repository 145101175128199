.block-photo-cursor {
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-l);

    --font-size-title: var(--font-size-higlightlarge);
    --font-size-lar: var(--font-size-higlightlarge);
    --font-size-med: var(--font-size-xxxxxxxlarge);
    --font-size-sm: var(--font-size-xxxxlarge);
    --font-size-xsm: var(--font-size-xlarge);
    
    --font-size: var(--font-size-base);
    --padding-bottom: #{space(16)};
    --padding-h: #{securePadding(space(4))};
    --colTitleStart: 2;
    --colTitleEnd: 12;
    --colStart: 5;
    --colEnd: 13;
    --sizeImage: 20vw;
    --bg-grad: linear-gradient(0deg, rgba(0,0,0,1) 1%, rgba(0,0,0,1) 65%, rgba(0,0,0,0) 100%);
    --headline-blend: none;
    --headline-height: 100vh;

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-s);
        
        --font-size-title: var(--font-size-xxxxlarge);
        --font-size-lar: var(--font-size-xxxxlarge);
        --font-size-med: var(--font-size-xlarge);
        --font-size-sm: var(--font-size-medium);
        --font-size-xsm: var(--font-size-xsmall);
        
        --font-size: var(--font-size-xsmall);
        --colTitleStart: 1;
        --colTitleEnd: 13;
        --colStart: 1;
        --colEnd: 13;
        --sizeImage: 40vw;
        --headline-blend: none;
        --headline-height: calc(var(--vh, 1vh) * 100);
    }
}

.palette-fluor .block-photo-cursor {
    --bg-grad: linear-gradient(0deg, rgba(203,255,14,1) 1%, rgba(203,255,14,1) 65%, rgba(203,255,14,0) 100%);
}

.block-photo-cursor {
    position: relative;
    padding: 0 var(--padding-h) var(--padding-bottom);
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    .line-parent {
        overflow: hidden;
    }

    > .images {
        position: absolute;
        top: 0;
        left: 0;
        width: var(--sizeImage);
    }

    > canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    > .headline {
        grid-column: var(--colTitleStart) / var(--colTitleEnd);
        padding: 0 var(--size-offset-big);
        position: relative;
        @include z-index(2);
        min-height: var(--headline-height);
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        mix-blend-mode: var(--headline-blend);
        pointer-events: none;

        &.--size-large {
            --font-size-title: var(--font-size-lar);
        }
    
        &.--size-medium {
            --font-size-title: var(--font-size-med);
        }
    
        &.--size-small {
            --font-size-title: var(--font-size-sm);
        }
    
        &.--size-xsmall {
            --font-size-title: var(--font-size-xsm);
        }

        > * {
            @include font-sans();
            font-size: var(--font-size-title);
        }
    }

    > .body {
        grid-column: var(--colStart) / var(--colEnd);
        @include font-sans();
        font-size: var(--font-size);
        padding-left: var(--size-offset-big);

        > * {
            @include z-index(2);
            position: relative;
        }
    }
}
