.block-quote {
    --font-size-lar: var(--font-size-xxxxxxxlarge);
    --font-size-med: var(--font-size-xxxxlarge);
    --font-size-sm: var(--font-size-xlarge);
    --font-size-xsm: var(--font-size-base);
    --padding-v: var(--padding-xxxxxxl);
    --padding-h: #{securePaddingBig(space(20))};

    @media (max-width: $smartphone) {
        --font-size-lar: var(--font-size-xlarge);
        --font-size-med: var(--font-size-medium);
        --font-size-sm: var(--font-size-xsmall);
        --font-size-xsm: var(--font-size-xxxsmall);
        --padding-v: var(--padding-xl);
        --padding-h: var(--padding-s);
    }

    *::-moz-selection {
        background: var(--black);
        color: var(--white);
    }

    *::selection {
        background: var(--black);
        color: var(--white);
    }
}

.block-quote {
    @include font-sans();

    background-color: var(--bg);
    color: var(--color);
    padding: var(--padding-v) var(--padding-h);

    blockquote {
        margin: 0;
    }

    &__wrapper {
        &.--size-large {
            font-size: var(--font-size-lar);
        }
    
        &.--size-medium {
            font-size: var(--font-size-med);
        }
    
        &.--size-small {
            font-size: var(--font-size-sm);
        }
    
        &.--size-xsmall {
            font-size: var(--font-size-xsm);
        }


        &.--align-center {
            text-align: center;
        }

        &.--align-left {
            text-align: left;
        }

        &.--align-right {
            text-align: right;
        }
    }
    
    .word-parent {
        overflow: hidden;
    }

    // > * {
    //     margin: 0 auto;
    //     text-align: center;
    // }
}
