:root {
    --white: #ffffff;
    --black: rgba(6, 8, 4, 1);
    --blackOpacity: rgba(6, 8, 4, 0.9);
    --grey: #808080;
    --gray-900: #5c5c5c;
    --gray-700: #666666;
    --gray-500: #808080;
    --gray-300: #b3b3b3;

    --primary: var(--black);
    --secondary: var(--white);
    --fluor: #cbff0e;
    --fluor-link: #78AA21;

    --assertive: #ff00ff;
    --focus: #ff00ff;
    --color-error: #e52217;
    --color-success: var(--black);

    --primary-color: rgba(6, 8, 4, 1);
    --primary-bg: #ffffff;
    --secondary-color: #808080;
    --secondary-bg: #ffffff;
}
