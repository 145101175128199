.legal {
    --font-size-h1: var(--font-size-xxxxxxxlarge);
    --font-size-h2: var(--font-size-base);
    --font-size: var(--font-size-small);
    --padding-top: calc(var(--header-height) + #{space(12)});
    --padding-bottom: #{space(8)};
    --padding-h: #{space(4)};

    @media (max-width: $smartphone) {
        --font-size-h1: var(--font-size-xlarge);
        --font-size-h2: var(--font-size-xxxsmall);
        --font-size: var(--font-size-xxsmall);
        --padding-top: calc(var(--header-height) + #{space(8)});
        --padding-bottom: #{space(4)};
        --padding-h: #{space(3)};
    }
}

.legal {
    @include font-sans();

    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
    font-size: var(--font-size);

    h1 {
        @include font-sans();
        font-size: var(--font-size-h1);
        margin-bottom: #{space(3)};
    }

    h2 {
        @include font-sans();
        color: var(--gray-700);
        font-size: var(--font-size-h2);
        margin-bottom: #{space(2)};
    }

    p {
        margin: 0 0 #{space(6)};
    }

    .wp-block-columns {
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        .wp-block-column:first-child {
            font-size: var(--font-size-h2);
            grid-column: 1 / 5;

            p {
                display: inline-block;
                position: relative;
            }

            .text {
                @include z-index($z-index-3D);
                position: relative;
            }

            .highlight {
                @include z-index($z-index-bg);
                background-color: var(--fluor);
                height: 32%;
                left: 0;
                position: absolute;
                top: 17%;
                width: 100%;
            }
        }

        .wp-block-column:last-child {
            grid-column: 6 / 12;
        }
    }

    @media (min-width: $smartphone) {
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        > * {
            grid-column: 5 / 13;
        }

        h1 {
            margin-bottom: #{space(4)};
        }

        p {
            margin: 0 0 #{space(12)};
        }
    }
}
