//↗ → ← ↖

@mixin link-underline() {
    --stroke: max(1px, 0.08em); 

    @include basic-a();
    text-decoration: none;
    background: 
		linear-gradient(to right, transparent, transparent),
		linear-gradient(to right, currentColor, currentColor);
	background-size: 100% var(--stroke), 0 var(--stroke);
    background-position: 100% calc(100% - .07em), 0 calc(100% - .07em);
    background-repeat: no-repeat;
	transition: background-size .4s;
    
    &:hover {
	    background-size: 0 var(--stroke), 100% var(--stroke);
    }

    &.--active,
    &.__link-active {
        pointer-events: none;
    }

    /// TOUCH
    @include isTouch() {
        overflow: visible;

        &:before {
            opacity: 0;
            transform: translateX(0);
        }

        > span {
            transform: translateX(0);
        }

        &:hover {
            &:before {
            opacity: 1;
            }
        }
    }
}

.link-underline {
    @include link-underline();
}
