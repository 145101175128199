::-moz-selection {
    background: var(--fluor);
    color: var(--black);
}

::selection {
    background: var(--fluor);
    color: var(--black);
}

html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    min-height: 100%;

    color: var(--primary-color);
    background: var(--primary-bg);

    main {
        width: 100%;
        overflow-x: hidden;
    }

    &:not(.__scroll-manual) {
        overflow: hidden;
        height: 100vh;

        .wrap {
            position: fixed;
            @include z-index($z-index-wrap);
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100vw;
        }
    }

    &.__scroll-manual {
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }
}
