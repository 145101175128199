.block-text-project {
    --font-size: var(--font-size-base);
    --padding-v: 0;
    --padding-h: #{securePadding(space(5))};
    --item-margin-top: #{space(40)};
    --item-margin-bottom: #{space(21)};
    --max-width: #{(990px / 16px) * 1rem};

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xsmall);
        --padding-v: 0;
        --padding-h: #{space(3)};
        --item-margin-top: #{space(18)};
        --item-margin-bottom: #{space(9)};
    }
}

.block-text-project {
    @include font-sans();

    font-size: var(--font-size);
    margin: var(--item-margin-top) 0 var(--item-margin-bottom);
    padding: 0 var(--padding-h);

    p:not(:last-child) {
        margin: 0 0 #{space(3)};
    }

    @media (max-width: $smartphone) {
        .text-left {
            margin-bottom: .5rem;
        }
    }

    @media (min-width: $smartphone) {
        > div {
            display: grid;
            grid-template-columns: repeat(12, 1fr);

            .text-left {
                grid-column: 1 / 4;
            }

            .text-right {
                grid-column: 5 / 12;
                max-width: var(--max-width);
            }
        }
    }
}
