#Header {
    --width: 100%;
    --height: var(--header-height);
    --color: var(--primary-color);
    --font-size: var(--font-size-xsmall);
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-l);
    --logo-width: #{(107px / 16px) * 1rem};
    --logo-width-2: #{(121px / 16px) * 1rem};

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-s);
    }

    &.__nav-open {
        --color: var(--black);
    }
}

#Header {
    @include z-index($z-index-header);

    color: var(--color);
    height: var(--header-height);
    padding: var(--padding-v) var(--padding-h);
    position: fixed;
    right: 0;
    top: 0;
    transform: translate3d(0, var(--y-header), 10px);
    transition: background-color 0.1s ease-out;
    width: var(--width);

    &.--with-bg {
        background-color: var(--primary-bg);
    }

    &.--not-found  {
        .logo-holder >.logo {
            #lottie-logo { display: block; }
            #logo { display: none; }
        }
    }

    .logo-holder {
        > .logo {
            display: flex;
            align-items: center;
            height: 100%;
            
            svg,
            span {
                width: var(--logo-width);
            }
            
            #lottie-logo {
                display: none;
                margin-top: rem(-5);
                margin-left: rem(-10);
                width: var(--logo-width-2);
            }

            path {
                fill: var(--color);
            }
        }
    }

    > nav {
        height: rem(40);
        display: flex;

        a {
            @include link-stroke();
            @include font-sans(1.2);
            font-size: var(--font-size);
            margin: 0 var(--padding-l) 0 0;
            padding: var(--padding-xxs) 0;
            text-transform: uppercase;

            display: flex;
            align-items: center;
        }
    }

    .up-btn {
        margin-right: rem(-4);
        position: absolute;
        right: var(--padding-h);
        top: var(--padding-v);
        
        svg {
            height: rem(40);
            width: rem(40);
        }

        path {
            fill: var(--color);
        }
    }

    .toggle-menu {
        align-items: center;
        display: none;
        justify-content: center;
        height: var(--toggle-width);
        margin-right: rem(-12);
        overflow: hidden;
        position: relative;
        width: var(--toggle-width);

        @include z-index($z-index-wrap);

        span {
            background: var(--color);
            display: block;
            height: rem(4);
            left: 50%;
            margin: 0;
            position: absolute;
            top: calc(50% - rem(2));
            transform: translate3d(-50%, 0, 0);
            width: rem(25);
            will-change: transform;

            &:first-child {
                transform: translate3d(-50%, -6px, 0);
            }
            &:last-child {
                transform: translate3d(-50%, 6px, 0);
            }
        }
    }
}

@media (max-width: $smartphone) {
    #Header {
        align-items: center;
        display: flex;
        justify-content: space-between;

        > nav {
            display: none;
        }

        .toggle-menu {
            display: block;
        }

        .up-btn {
            svg {
                height: rem(36);
                width: rem(36);
            }
        }
    }
}

@media (min-width: $smartphone) {
    #Header {
        @include grid-container();
        
        justify-content: center;
        
        .logo-holder {
            grid-column: 1 / 5;
        }

        > nav {
            grid-column: 5 / 11;
        }
    }
}
