.block-legacy-projects {
    --font-size-big: var(--font-size-xxxxlarge);
    --font-size-categ: var(--font-size-large);
    --font-size: var(--font-size-base);
    --font-size-tag: var(--font-size-base);
    --padding-v: var(--padding-xxxxxxl);
    --padding-h: #{securePadding(space(5))};

    @media (max-width: $smartphone) {
        --font-size-big: var(--font-size-large);
        --font-size-categ: var(--font-size-base);
        --font-size: var(--font-size-xsmall);
        --font-size-tag: var(--font-size-xxxxxsmall);
        --padding-v: var(--padding-xxxl);
        --padding-h: var(--padding-s);
    }
}

.block-legacy-projects {
    @include font-sans();

    // &__categories {
    //     padding: 0 var(--padding-h);
    //     font-size: var(--font-size-categ);

    //     > div {
    //         button {
    //             color: var(--gray-700);
    //             position: relative;

    //             &.--active {
    //                 color: var(--white);
    //                 pointer-events: none;
    //                 cursor: pointer;
    //             }

    //             sup {
    //                 position: absolute;
    //                 right: #{space(-2.5)};
    //                 top: #{space(0.75)};
    //                 transform: scale3d(0.8, 0.8, 0.8);
    //             }
    //         }

    //         li:not(:last-child) {
    //             margin-bottom: #{space(3)};
    //         }

    //         @include isCursor() {
    //             button:hover {
    //                 color: var(--white);
    //             }
    //         }
    //     }
    // }

    &__wrapper {
        padding: var(--padding-v) var(--padding-h) #{(200px / 16px) * 1rem};
        position: relative;
    }

    &__decade {
        display: flex;
        position: relative;

        .decade {
            color: var(--gray-700);
            font-size: var(--font-size-big);
            min-width: rem(70);
            position: relative;
            width: rem(70);

            span {
                display: block;
                left: 0;
                position: absolute;
                transform-origin: top left;
                top: #{space(2)};
                transform: rotate(-90deg) translateX(-100%);
                white-space: nowrap;
            }
        }

        .line {
            content: '';
            background-color: var(--gray-700);
            bottom: 0;
            height: 1px;
            left: 0;
            position: absolute;
            width: 100%;
        }

        & > .line:first-child {
            top: 0;
        }

        & > .line:last-child {
            bottom: 0;
        }

        .projects {
            flex-grow: 1;

            li {
                position: relative;
            }
        }

        .legacy {
            --color: var(--white);

            @include basic-a();
            color: var(--color);
            display: block;
            font-size: var(--font-size);
            padding: #{space(2)} 0;
            position: relative;
            text-decoration: none;
            overflow: hidden;

            > span {
                align-items: center;
                display: flex;
                justify-content: space-between;
            }

            li:not(:last-child) & {
                border-bottom: 1px solid currentColor;
            }

            &[data-disabled] {
                --color: var(--gray-700);

                pointer-events: none;
                cursor: pointer;
            }
        }

        @include isCursor() {
            a.legacy:not([data-disabled]) {
                --scale: 0;

                .__mouse-up & {
                    --transform-origin-default: bottom;
                    --transform-origin-hide: top;
                }

                .__mouse-down & {
                    --transform-origin-default: top;
                    --transform-origin-hide: bottom;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -1em;
                    width: calc(100% + 2em);
                    height: 100%;
                    background-color: var(--fluor);
                    transition-timing-function: var(--ease-out-quad);
                    transition: transform 0.15s;
                    z-index: -1;
                    transform: scaleY(var(--scale));
                    transform-origin: var(--transform-origin-hide);
                }

                &:hover {
                    color: var(--black);
                    --scale: 1;

                    &::before {
                        transform-origin: var(--transform-origin-default);
                        transition-timing-function: var(--ease-out-quad);
                        transition: transform 0.15s;
                    }
                }
            }
        }

        .tag {
            font-size: var(--font-size-tag);
        }
    }

    @media (max-width: $smartphone) {
        .tag {
            display: none;
        }
        // .legacy {
        //     &.--disabled {
        //         display: none;
        //     }
        // }

        // &__categories {
        //     > div ul:first-child {
        //         margin-bottom: #{space(3)};
        //     }
        // }
    }

    @media (min-width: $smartphone) {
        // &__categories {
        //     display: grid;
        //     @include grid-container();

        //     > div {
        //         display: flex;
        //         grid-column: 5 / 12;
        //         max-width: var(--max-width-box);

        //         ul {
        //             min-width: 50%;
        //             width: 50%;
        //         }
        //     }
        // }

        &__decade {
            .decade {
                min-width: rem(110);
                width: rem(110);

                span {
                    top: #{space(4)};
                }
            }

            .legacy {
                padding: #{space(2.25)} #{space(1)};

                // &.--disabled {
                //     cursor: pointer;
                //     pointer-events: none;
                // }
            }
        }
    }
}
