.block-text-reveal {
    --padding-v: #{space(19)};
    --margin-text: #{space(9)};
    --padding-h: var(--padding-l);
    --colStart: 5;
    --colEnd: 10;
    
    --color: var(--primary-color);
    --bg: var(--primary-bg);
    --font-size: var(--font-size-xxlarge);

    @media (max-width: $smartphone) {
        --padding-v: #{space(11)};
        --padding-h: var(--padding-s);
        --margin-text: #{space(7)};
        --font-size: var(--font-size-base);
        --colStart: 1;
        --colEnd: 13;
    }
}

.block-text-reveal__image {
    position: relative;
    @include grid-container();
        
    padding-top: var(--padding-v);
    padding-bottom: var(--margin-text);
            
    > figure {
        grid-column: var(--colStart) / var(--colEnd);
    }
}

.block-text-reveal__text {
    position: relative;
    @include grid-container();
    padding-bottom: var(--padding-v);
    margin-top: -1px;
    
    @include font-sans();
    font-size: var(--font-size);
    color: var(--color);

    > .text {
        grid-column: var(--colStart) / var(--colEnd);
    }
}