.block-contact {
    --padding-top: #{space(11.5)};
    --padding-h: var(--padding-l);
    --font-size-big: var(--font-size-xxxxlarge);
    --font-size: var(--font-size-base);
    --font-size-link: var(--font-size-xxlarge);

    @media (max-width: $smartphone) {
        --font-size-big: var(--font-size-base);
        --font-size: var(--font-size-small);
        --font-size-link: var(--font-size-base);
        --padding-top: #{space(6)};
        --padding-h: var(--padding-s);
    }
}

.block-contact {
    @include font-sans();
    font-size: var(--font-size);
    padding: var(--padding-top) var(--padding-h) 0;

    h2 {
        @include font-sans();
        font-size: var(--font-size-big);
        margin-bottom: #{space(2)};
    }

    p {
        margin-bottom: #{space(5)};
    }

    a {
        @include basic-a();
        color: var(--white);
    }

    &__item {
        margin-bottom: #{space(14)};
    }

    .links {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        a {
            @include link-stroke();
            color: var(--gray-500);
            display: inline-block;
            font-size: var(--font-size-link);

            &:not(:last-child) {
                margin-bottom: #{space(3)};
            }

            @include isCursor() {
                &:hover {
                    color: var(--white);
                }
            }
        }
    }

    .icon {
        padding-right: var(--padding-l);

        .media-holder {
            height: auto;
        }
    }

    .cta {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        margin-top: #{space(5)};
    }

    @media (max-width: $smartphone) {
        .icon {
            margin-bottom: #{space(6)};

            .media-holder {
                width: rem(240);
            }
        }
    }

    @media (min-width: $smartphone) {
        &__item {
            @include grid-container();

            margin-bottom: #{space(32)};
            padding-left: 0;
            padding-right: 0;
        }

        .icon {
            grid-column: 1/4;

            .media-holder {
                max-width: rem(322);
                width: 100%;
            }
        }

        .content {
            grid-column: 5 / 12;
        }

        .links {
            display: flex;
            flex-direction: column;
        }
    }
}
