#Cookies {
    --color: var(--secondary-color);
    --bg: var(--primary-color);
    --padding-v: #{space(2)};
    --padding-h: #{space(2)};
    --font-size: var(--font-size-mid);
    --font-size-big: var(--font-size-base);

    &.__success {
        --bg: var(--color-success);
        --color: var(--black);
    }

    &.__error {
        --bg: var(--color-error);
        --color: var(--white);
    }

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }
}


#Cookies {
    @include font-sans();
    @include z-index($z-index-windows);

    background-color: var(--bg);
    bottom: 0;
    color: var(--color);
    left: auto;
    padding: var(--padding-v) var(--padding-h);
    position: fixed;
    right: 0;
    width: 100%;

    .content {
        display: block;
        flex: auto;
        margin-bottom: #{space(2)};
        position: relative;
    }

    .controls {
        text-align: center;
        padding: 0;
        flex: auto;
    }

    button {
        @include basic-a();

        font-size: var(--font-size);
        padding: #{space(2)};
        width: 100%;
    }
    
    .btn {
        background-color: var(--fluor);
        color: var(--black);
        font-size: var(--font-size-big);
        letter-spacing: var(--letter-spacing);
        line-height: 1;
        margin-bottom: #{space()};

        @include isCursor() {
            &:hover {
                background: var(--secondary-color);
                color: var(--primary-color);
            }
        }
    }

    p {
        font-size: var(--font-size);
        margin: 0;

        b, strong {
            @include font-sans-bold();
        }

        a {
            @include basic-a();

            text-decoration: underline;
            color: inherit;
        }
    }

    @media (min-width: $smartphone) {
        max-width: rem(440);
    }
}
