.block-team {
    --font-size-title: var(--font-size-xxxxxlarge);
    --font-size-summary: var(--font-size-base);
    --font-size-name: var(--font-size-small);

    --padding-v: #{space(17)};
    --padding-h: #{securePadding(space(5))};
    --margin-slider: #{space(17)};
    --margin-name: 1em;
    --gap: #{space(3)};
    --slideSize: 25vw;
    --slideEmptySize: var(--padding-h);
    --imageWidth: 100%;

    --colStart: 6;
    --colEnd: 13;

    --color: var(--primary-color);
    --bg: var(--primary-bg);

    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-large);
        --font-size-summary: var(--font-size-xxsmall);
        --font-size-name: var(--font-size-xxsmall);

        --padding-v: #{space(8)};
        --padding-h: var(--padding-s);
        --margin-slider: #{space(8)};
        --gap: #{space(2)};
        --slideSize: calc(100vw - var(--gap) * 4);
        --slideEmptySize: var(--gap);
        --imageWidth: 100%;

        --colStart: 2;
        --colEnd: 14;
    }
}

.block-team {
    position: relative;
    width: 100vw;
    display: grid;
    overflow: hidden;

    grid-template-columns: var(--padding-h) repeat(12, 1fr) var(--padding-h);
    padding: var(--padding-v) 0 var(--padding-v);

    @include font-sans();
    color: var(--primary-color);
    
    > .headline {
        @include font-sans(1.3);
        grid-column: var(--colStart) / var(--colEnd);
        font-size: var(--font-size-title);
        font-weight: normal;
        margin-bottom: 0.63em;
    }

    > .body {
        grid-column: var(--colStart) / var(--colEnd);
        font-size: var(--font-size-summary);
    }
}

.block-team__slider {
    margin-top: var(--margin-slider);
    grid-column: 1 / 15;
    user-select: none;

    > .holder {
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap;
        height: auto;
    }

    @media (max-width: $smartphone) {
        transform: none !important;
    }
}

.block-team__slider__item {
    background: transparent;
    border: 0;
    margin: 0;

    &:empty {
        width: var(--slideEmptySize);
        min-width: var(--slideEmptySize);
        height: 10px;
    }

    &:not(:empty) {
        min-width: var(--slideSize);
        width: var(--slideSize);
    }

    &:not(:empty):not(:nth-child(2)) {
        margin-left: var(--gap);
    }
    figure {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
    figcaption {
        margin-top: var(--margin-name);
        font-size: var(--font-size-name);

        > span {
            display: block;
            opacity: 0.7;
        }
    }

    img {
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }

    > .media-holder img {
        width: var(--imageWidth);
    }
}
