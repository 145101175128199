#Sidemenu {
    --bg: var(--fluor);
    --color: var(--black);
    --font-size: var(--font-size-xxlarge);
    --font-size-lang: var(--font-size-xsmall);
    --padding-top: calc(var(--header-height) + var(--padding-s));
    --padding-bottom: var(--padding-s);
    --padding-h: var(--padding-s);

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xlarge);
        --padding-top: calc(var(--header-height) + var(--padding-xl));
        --padding-bottom: var(--padding-s);
        --padding-h: var(--padding-xs);
    }
}

#Sidemenu {
    background-color: var(--bg);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;

    @include z-index($z-index-sidemenu);
    @include font-sans();

    font-size: var(--font-size);

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    .link {
        @include basic-a();

        color: var(--color);

        &[aria-current="page"] {
            @include font-sans-bold();

            pointer-events: none;
            span:after { opacity: 1; }
        }

        span { position: relative; }

        span:after {
            bottom: 0;
            background-color: currentColor;
            content: '';
            height: 2px;
            left: 0;
            opacity: 0;
            position: absolute;
            width: 100%;
        }
    }

    & > .content {
        height: calc(var(--vh, 1vh) * 100);
        left: 0;
        padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
        position: absolute;
        top: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include z-index(1);
    }

    .list {
        padding-bottom: var(--padding-xxxxxxxl);
        text-transform: uppercase;

        li { overflow: hidden; }

        .link {
            height: calc(var(--font-size) * var(--line-height-sans));
            overflow: hidden;
            position: relative;

            span {
                display: inline-block;
                position: relative;
            }
        }
    }

    .rrss,
    .langs {
        display: flex;
        padding: var(--padding-xs) 0;

        a {
            @include link-stroke();
            @include font-sans(1);
            font-size: var(--font-size-lang);
            margin: 0 var(--padding-s) 0 0;
            color: var(--black);
        }
    }

    .langs {
        justify-content: flex-start;
        text-transform: uppercase;
    }

    .rrss {
        justify-content: space-between;

        a {
            margin: 0;
        }
    }

    @media (max-width: $smartphone) {
        .list li:not(:last-child) { margin-bottom: #{space(3)}; }
        // .list li .sublist  { padding-left: var(--padding-s); }
    }

    @media (min-width: $smartphone) {
        .contact {
            .link .text { width: rem(318); }
        }
    }
}