input,button, embed, video, object {
  outline: none;
  border: 0 solid;
}
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing:grayscale;
  text-rendering: optimizeLegibility;
}
button {
  background-color: transparent;
  padding: 0;

  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */
  -webkit-touch-callout: none;
  -moz-touch-callout: none;
  -ms-touch-callout: none;
  touch-callout: none;
}

.media-holder{
    --aspect: 1;

    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    background-position: center center;
    background-size: cover;
    padding-top: calc(100% * var(--aspect));

  video,
  iframe,
  [data-item-load],
  [data-item-aspect-ratio],
  [data-item-loaded],
  [data-item-preload],
  [data-item-lazyload] {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: auto;
  }
}

[data-item-load],
[data-item-loaded],
[data-item-preload],
[data-item-lazyload] {
  &:not(img) {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
