@keyframes load {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 0;
    }

    // 66.66% {
    //     // display: none;
        
    //     &:nth-child(4) {
    //         display: block;
    //     }
    // }

    // 100% {
    //     display: none;
    // }
}

#page-loader {
    --bg: var(--black);

    @include z-index($z-index-preloader);
    position: fixed;
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bg);
    
    span {
        animation: load 1s linear infinite;
        opacity: 0;
        position: absolute;
        width: rem(120);
        
        svg {
            width: 100%;
        }
      
        &:nth-child(2) {
            animation-delay: .25s;
        }
        &:nth-child(3) {
            animation-delay: .5s;
        }
        &:nth-child(4) {
            animation-delay: .75s;
        }
    }
}