.block-media-project {
    --padding-v: #{space(8)};
    --padding-h: #{securePadding(space(4))};
    --item-margin: #{space(3)};

    @media (max-width: $smartphone) {
        --padding-v: #{space(5)};
        --padding-h: #{space(3)};
        --item-margin: #{space(3)};
    }
}

.block-media-project {
    padding: 0 var(--padding-h);
    margin: var(--padding-v) 0;

    &__wrapper.--hasDisplace {
        overflow: hidden;

        .media-holder {
            transform: scale3d(1.4, 1.4, 1);
        }
    }

    @media (max-width: $smartphone) {
        & + .block-image-left-right,
        & + .block-media-project {
            margin-top: #{space(-2)};
        }

        &__wrapper:not(:last-child) {
            margin-bottom: var(--item-margin);
        }
    }

    @media (min-width: $smartphone) {
        > div {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;
            gap: var(--item-margin);

            > * {
                flex-grow: 1;
            }
        }

        & + .block-image-left-right,
        & + .block-media-project {
            margin-top: #{space(-5)};
        }
    }
}
