#Reel {
    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    align-items: center;
    background-color: var(--black);
    bottom: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    width: 100%;

    // @include z-index($z-index-player);
    @include z-index($z-index-windows);

    > div {
        height: 100%;
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .close {
        @include z-index($z-index-3D);
        // @include z-index($z-index-player);
        // @include z-index($z-index-player);
        // @include z-index($z-index-interface);

        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
    }

    .plaver-video-full {
        --font-size-timer: 20.5vw;
        --color: var(--white);
        --interface-height: var(--header-height);

        background: var(--black);
        cursor: none;
        opacity: 0;
        position: relative;
        object-fit: contain;
        object-position: center center;
        width: 100%;

        * {
            cursor: none;
        }

        > div {
            opacity: 0;
        }

        > video {
            width: 100%;
            height: 100%;
        }

        .__showInterface & {
            > div {
                opacity: 1;
            }

            cursor: auto;

            * {
                cursor: auto;
            }
        }

        .__showInterfaceForce & {
            > div {
                opacity: 1;
            }

            cursor: auto;

            * {
                cursor: auto;
            }
        }

        // &__layer {
        //     @include z-index($z-index-player);

        //     bottom: 0;
        //     height: 100%;
        //     left: 0;
        //     position: absolute;
        //     width: 100%;
        // }
    }

    //TIMER
    .plaver-video-full__timer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include font-sans(1);

        font-size: var(--font-size-timer);
        color: var(--color);
        background-color: rgba(0, 0, 0, 0.45);
    }

    .plaver-video-full__interface {
        height: var(--interface-height);
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 var(--padding-m);

        display: flex;
        align-items: center;
        justify-content: center;

        > span {
            flex: 0;
            @include font-sans(1);
            font-size: var(--font-size-base);
            color: var(--color);
        }

        > .plaver-video-full__progress {
            flex: 1;
        }
    }

    //PROGRESS
    .plaver-video-full__progress {
        cursor: pointer;
        height: 8px;
        position: relative;
        margin-bottom: #{space(0.75)};

        &:before {
            @include pseudo-element-absolute();
            top: -40px;
            width: 100%;
            height: calc(100% + 80px);
        }

        > .track,
        > .thumb {
            position: absolute;
            top: rem(-4);
            left: 0;
            width: 100%;
            height: rem(15);
        }

        > .thumb {
            background: var(--fluor);
            transform: scaleX(0);
            transform-origin: 0 0;
        }

        > .track {
            background-color: var(--color);
            opacity: 0.4;
        }
    }

    .plaver-video-full__controls {
        flex: 0 0 80px;
        height: var(--interface-height);
        margin-right: #{space(3)};

        &.--play {
            .__play,
            .__muted {
                display: none;
            }
            .__pause {
                display: block;
            }
        }

        &.--muted {
            .__play,
            .__pause {
                display: none;
            }
            .__muted {
                display: block;
            }
        }

        &.--pause {
            .__pause,
            .__muted {
                display: none;
            }
            .__play {
                display: block;
            }
        }
    }

    .plaver-video-full__controls > button {
        position: relative;

        width: 100%;
        height: 100%;
        background: transparent;
        padding: 0;
        border: none;

        @include basic-a();
        @include font-sans(1);

        font-size: var(--font-size-base);
        color: var(--color);

        &.__full {
            cursor: pointer;
        }
    }

    ///SHOW
    @media (max-width: $smartphone) {
        .plaver-video-full {
            --interface-height: #{rem(40)};
        }

        .plaver-video-full__controls {
            flex: 0 0 180px;
            display: flex;
        }

        .plaver-video-full__controls > button {
            width: 60px;

            &.__full {
                width: 120px;
            }
        }

        .plaver-video-full {
            > .plaver-video-full__interface {
                opacity: 1;
            }
        }

        .plaver-video-full__interface {
            padding: 0 var(--padding-xs);
        }
    }
}
