.button {
    @include font-sans(1);

    background: transparent;
    border: 1px solid var(--black);
    color: var(--black);
    margin-top: var(--padding-xs);
    padding: #{space(1.75)} #{space(4.5)} #{space(1.25)};
    text-transform: uppercase;

    &:hover {
        background: var(--secondary);
        color: var(--primary);

        @include isTouch() {
            background: var(--secondary);
            color: var(--primary);
        }
    }

    &--full {
        background: var(--black);
        color: var(--white);

        @include isCursor() {
            &:hover {
                background: var(--black);
                color: var(--white);
            }
        }
    }

    &--hollow {
        border: 1px solid currentColor;
    }
    
    &--fluor {
        background: var(--fluor);
        border: transparent;
        text-decoration: underline;
        padding: #{space(2)} #{space(4.5)};

        &.button--hover-black {
            @include isCursor() {
                &:hover {
                    background: var(--black);
                    color: var(--white);
                }
            }
        }
    }

    @media (min-width: $smartphone) {
        &--fluor {
            padding: #{space(2.5)} #{space(6)};
        }
    }
}
