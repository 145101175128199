.block-join-team {
    --font-size-h1: var(--font-size-xxxxxxxlarge);
    --font-size-ok: var(--font-size-xxxlarge);
    --font-size: var(--font-size-xxsmall);
    --font-size-file: var(--font-size-xxxsmall);
    --padding-top: calc(var(--header-height) + #{space(12)});
    --padding-bottom: #{space(32)};
    --padding-h: #{securePadding(space(4))};
    
    @media (max-width: $smartphone) {
        --font-size-h1: var(--font-size-xlarge);
        --font-size-ok: var(--font-size-small);
        --font-size: var(--font-size-xxxsmall);
        --font-size-file: var(--font-size-xxxxsmall);
        --padding-top: calc(var(--header-height) + #{space(8)});
        --padding-bottom: #{space(14)};
        --padding-h: #{space(3)};
    }
}

.block-join-team {
    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);

    &__wrapper {
        position: relative;
    }

    .line-parent {
        overflow: hidden;
    }

    .ok,
    .ko {
        @include z-index($z-index-3D);

        background-color: var(--white);
        font-size: var(--font-size-ok);
        height: 110%;
        margin-top: #{space(-2)};
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        visibility: hidden;
        width: 100%;
    }

    .form,
    .ok,
    .ko {
        @include font-sans();
    }

    .headline {
        @include font-sans(1.4);
        font-size: var(--font-size-h1);
        margin: 0 0 #{space(6)};
    }

    .form {
        margin-top: #{space(3)};

        &__footer {
            justify-content: flex-start;
        }

        .role {
            position: absolute;

            &[disabled] {
                display: none;
            }
        }

        fieldset.--checkbox {
            margin-top: #{space(3)};
        }

        .filename {
            display: inline-block;
            font-size: var(--font-size-file);
            margin-left: #{space()};
        }
    }

    @media (min-width: $smartphone) {
        &__wrapper {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
        }

        .headline {
            grid-column: 5 / 12;
        }

        .form {
            grid-column: 5 / 10;
        }

        .ok,
        .ko {
            grid-column: 4 / 12;
        }

        .headline {
            margin: 0 0 #{space(7)};
        }

        .form {
            &__wrapper {
                display: flex;
                flex-wrap: wrap;
                gap: #{space(5)};
            }

            fieldset {
                width: calc(50% - #{space(2.5)});

                &.--checkbox {
                    // margin-top: #{space(5)};
                    width: 100%;
                }
            }
        }
    }
}
